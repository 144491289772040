import { Markdown } from "@components/Markdown/MarkdownComponent";
import { colors } from "@components/Theme/colors";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AppBar, Box, Card, CardContent, Container, Divider, Grid, Stack, ToggleButton, ToggleButtonGroup, Toolbar, Typography } from "@mui/material";
import { Image } from "components";
import React, { useEffect, useState } from "react";
import { APITypes, ECluster } from "utils/api.types";
import { fetchPositionsWithCluster } from "utils/fetchingFunctions";
export interface TrackITClusterProps {
  block: APITypes.Components.ContentBlocks.TrackITCluster;
}
const TrackITCluster = ({
  block
}: TrackITClusterProps) => {
  const [descriptionIndex, setDescriptionIndex] = React.useState<number>(0);
  const [positions, setPositions] = useState<APITypes.Components.Job[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchPositionsWithCluster("nl", 0, 3, ECluster.IT);
      setPositions(res.data);
    };
    fetchData();
  }, []);
  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: number) => {
    setDescriptionIndex(newAlignment);
  };
  const {
    textBlock,
    menuItem,
    backgroundTextBlock,
    title
  } = block;
  return <Box sx={{
    backgroundColor: colors.greyColor.main,
    pt: {
      xs: 10,
      md: 10
    }
  }} data-sentry-element="Box" data-sentry-component="TrackITCluster" data-sentry-source-file="TrackITCluster.tsx">
      <Container maxWidth={false} disableGutters data-sentry-element="Container" data-sentry-source-file="TrackITCluster.tsx">
        <Stack flexDirection={"row"} justifyContent={"end"} sx={{
        display: {
          xs: "none",
          lg: "flex"
        }
      }} data-sentry-element="Stack" data-sentry-source-file="TrackITCluster.tsx">
          {positions.length > 0 && block?.blueLink && <Box>
              <Box sx={{
            padding: "18px",
            maxWidth: "420px",
            backgroundColor: colors.green.main,
            color: colors.primary.main,
            fontSize: "21px",
            fontWeight: 700,
            textAlign: "center",
            zIndex: 2,
            a: {
              color: colors.secondary.main
            }
          }}>
                <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"} gap={2}>
                  <Typography component={"div"}>
                    <Markdown content={block.blueLink} isOpenLinkNewTab={false} />
                  </Typography>
                  <Divider orientation="vertical" variant="middle" flexItem sx={{
                backgroundColor: colors.primary.main
              }} />
                  <VisibilityIcon />
                </Stack>
              </Box>
            </Box>}
        </Stack>

        <Stack flexDirection={"row"} data-sentry-element="Stack" data-sentry-source-file="TrackITCluster.tsx">
          <Container sx={{
          display: "flex"
        }} data-sentry-element="Container" data-sentry-source-file="TrackITCluster.tsx">
            <Stack sx={{
            flexDirection: {
              md: "row"
            }
          }} justifyContent={"center"} gap={4} data-sentry-element="Stack" data-sentry-source-file="TrackITCluster.tsx">
              <Typography variant="h2" sx={{
              flex: 1,
              textAlign: {
                md: "right"
              }
            }} data-sentry-element="Typography" data-sentry-source-file="TrackITCluster.tsx">
                {textBlock.title}
              </Typography>
              <Typography component={"div"} sx={{
              flex: 2,
              mt: 4,
              "& li::marker": {
                content: "'→'",
                color: colors.pink.main,
                fontSize: "24px"
              },
              "& li": {
                pl: "10px",
                py: "4px"
              }
            }} data-sentry-element="Typography" data-sentry-source-file="TrackITCluster.tsx">
                <Markdown content={textBlock.description} data-sentry-element="Markdown" data-sentry-source-file="TrackITCluster.tsx" />
              </Typography>
            </Stack>
          </Container>
        </Stack>
      </Container>
      <Box mt={16} data-sentry-element="Box" data-sentry-source-file="TrackITCluster.tsx">
        <AppBar component="nav" position="sticky" sx={{
        width: "100%",
        height: "32px",
        background: colors.greyColor.main,
        boxShadow: "none"
      }} data-sentry-element="AppBar" data-sentry-source-file="TrackITCluster.tsx">
          <Toolbar data-sentry-element="Toolbar" data-sentry-source-file="TrackITCluster.tsx">
            <Grid container direction="row" justifyContent="center" spacing={6} data-sentry-element="Grid" data-sentry-source-file="TrackITCluster.tsx">
              <ToggleButtonGroup color="primary" value={descriptionIndex} exclusive onChange={handleChange} aria-label="Platform" sx={{
              overflowY: "hidden",
              overflowX: "auto",
              whiteSpace: "nowrap"
            }} data-sentry-element="ToggleButtonGroup" data-sentry-source-file="TrackITCluster.tsx">
                {menuItem.map((item, i) => <ToggleButton key={i} value={i} sx={{
                "&.MuiToggleButtonGroup-grouped": {
                  mx: 4,
                  border: "0px"
                },
                color: "#1B1B1B",
                height: "4px",
                textTransform: "none",
                marginTop: "8px",
                paddingBottom: "40px",
                marginBottom: "14px",
                bgcolor: "transparent",
                "&.Mui-selected": {
                  borderBottom: `4px solid ${colors.secondary.main}`,
                  color: colors.secondary.main,
                  bgcolor: "transparent"
                },
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent"
                }
              }}>
                    <Typography sx={{
                  fontSize: {
                    lg: "30px",
                    xs: "30px"
                  },
                  fontWeight: {
                    lg: 700,
                    xs: 600
                  }
                }}>
                      {item.title}
                    </Typography>
                  </ToggleButton>)}
              </ToggleButtonGroup>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
      <Box sx={{
      py: 18,
      display: "flex",
      alignItems: "center",
      backgroundColor: colors.white.main
    }} data-sentry-element="Box" data-sentry-source-file="TrackITCluster.tsx">
        <Container data-sentry-element="Container" data-sentry-source-file="TrackITCluster.tsx">
          <Stack justifyContent={"center"} alignItems={"center"} gap={8} sx={{
          flexDirection: {
            xs: "column-reverse",
            lg: "row"
          }
        }} data-sentry-element="Stack" data-sentry-source-file="TrackITCluster.tsx">
            <Stack sx={{
            flex: 1,
            width: "100%",
            height: "100%"
          }} data-sentry-element="Stack" data-sentry-source-file="TrackITCluster.tsx">
              {menuItem[descriptionIndex ?? 0]?.mentorBlock && <MentorBlock mentorBlock={menuItem[descriptionIndex ?? 0].mentorBlock} />}
            </Stack>
            <Stack sx={{
            flex: 1,
            width: "100%",
            height: "100%"
          }} data-sentry-element="Stack" data-sentry-source-file="TrackITCluster.tsx">
              <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="TrackITCluster.tsx">{backgroundTextBlock.title}</Typography>
              <Typography component={"div"} data-sentry-element="Typography" data-sentry-source-file="TrackITCluster.tsx">
                <Markdown content={backgroundTextBlock.description} data-sentry-element="Markdown" data-sentry-source-file="TrackITCluster.tsx" />
              </Typography>
              <Typography component={"div"} data-sentry-element="Typography" data-sentry-source-file="TrackITCluster.tsx">
                <Markdown content={menuItem[descriptionIndex ?? 0].description} data-sentry-element="Markdown" data-sentry-source-file="TrackITCluster.tsx" />
              </Typography>
            </Stack>
            {/* <Grid item xs={6}>
                  <Carousel
                    autoPlay={false}
                    navButtonsAlwaysInvisible={true}
                    indicatorIconButtonProps={{
                      style: {
                        padding: "24px", // 1
                        color: "white", // 3
                      },
                    }}
                    navButtonsProps={{
                      // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                      style: {
                        borderRadius: 0,
                        backgroundColor: "white",
                        color: "black",
                      },
                    }}
                  >
                    {mentorBlock.map((item, i) => (
                      <MentorBlock key={i} mentorBlock={item} />
                    ))}
                  </Carousel>
                </Grid> */}
          </Stack>
        </Container>
      </Box>
    </Box>;
};
export default TrackITCluster;
function MentorBlock({
  mentorBlock
}: {
  mentorBlock: APITypes.Components.MentorBlock;
}) {
  return <Card sx={{
    background: "linear-gradient(180deg, #000000 5.58%, #2F2B2B 53.68%)",
    color: "white"
  }} data-sentry-element="Card" data-sentry-component="MentorBlock" data-sentry-source-file="TrackITCluster.tsx">
      <Grid container direction={"row"} data-sentry-element="Grid" data-sentry-source-file="TrackITCluster.tsx">
        <Grid item xs={12} sm={4} display="flex" data-sentry-element="Grid" data-sentry-source-file="TrackITCluster.tsx">
          <Image image={mentorBlock.image} alt={mentorBlock.image.name} layout={"responsive"} style={{
          objectFit: "cover"
        }} data-sentry-element="Image" data-sentry-source-file="TrackITCluster.tsx" />
        </Grid>
        <Grid item xs={12} sm={8} data-sentry-element="Grid" data-sentry-source-file="TrackITCluster.tsx">
          <CardContent data-sentry-element="CardContent" data-sentry-source-file="TrackITCluster.tsx">
            <Box data-sentry-element="Box" data-sentry-source-file="TrackITCluster.tsx">
              <Box mt={2} data-sentry-element="Box" data-sentry-source-file="TrackITCluster.tsx" />
              <Typography component={"div"} align={"left"} sx={{
              color: colors.secondary.main,
              fontWeight: 700
            }} data-sentry-element="Typography" data-sentry-source-file="TrackITCluster.tsx">
                {mentorBlock.subTitle}
              </Typography>
              <Typography component={"div"} align={"left"} style={{
              color: "white"
            }} data-sentry-element="Typography" data-sentry-source-file="TrackITCluster.tsx">
                {mentorBlock.title}
              </Typography>
              <Typography color="text.secondary" component={"div"} style={{
              color: "white"
            }} sx={{
              "& a": {
                color: colors.secondary.main
              }
            }} data-sentry-element="Typography" data-sentry-source-file="TrackITCluster.tsx">
                <Markdown content={mentorBlock.description} data-sentry-element="Markdown" data-sentry-source-file="TrackITCluster.tsx" />
              </Typography>
            </Box>
          </CardContent>
        </Grid>
      </Grid>
    </Card>;
}