import { Markdown } from "@components/Markdown/MarkdownComponent";
import { colors } from "@components/Theme/colors";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Card, CardContent, Container, Grid, Link, List, Stack, Typography } from "@mui/material";
import { Image } from "components";
import NextLink from "next/link";
import Carousel from "react-material-ui-carousel";
import { APITypes } from "utils/api.types";
export interface NewsPreviewProps {
  block: APITypes.Components.ContentBlocks.PreviewNewsBlock;
}
const NewsPreview = ({
  block
}: NewsPreviewProps) => {
  const {
    title,
    socialLinks,
    socialLinksTitle,
    newsPages
  } = block;
  return <Box py={10} sx={{
    backgroundColor: "#FFF"
  }} data-sentry-element="Box" data-sentry-component="NewsPreview" data-sentry-source-file="NewsPreview.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="NewsPreview.tsx">
        <Grid container direction="row" justifyContent={"space-between"} alignItems="center" data-sentry-element="Grid" data-sentry-source-file="NewsPreview.tsx">
          <Box mb={6} data-sentry-element="Box" data-sentry-source-file="NewsPreview.tsx">
            <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="NewsPreview.tsx">{title}</Typography>
          </Box>
          <Grid container item xs={12} md={7} justifyContent={"flex-end"} sx={{
          display: {
            xs: "none",
            md: "flex"
          }
        }} data-sentry-element="Grid" data-sentry-source-file="NewsPreview.tsx">
            <Box mb={5} sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            alignItems: "center"
          }} data-sentry-element="Box" data-sentry-source-file="NewsPreview.tsx">
              <Box mt={1} data-sentry-element="Box" data-sentry-source-file="NewsPreview.tsx">
                <List data-sentry-element="List" data-sentry-source-file="NewsPreview.tsx">
                  {socialLinks.map((item, ind) => <Link href={item.href ?? "/"} underline="none" key={ind + item.name} sx={{
                  mx: 1,
                  justifyContent: "center"
                }}>
                      <Image alt={item.icon?.name} image={item.icon} style={{
                    height: "26px",
                    width: "26px",
                    padding: "4px",
                    backgroundColor: colors.greyColor.main
                  }} />
                    </Link>)}
                </List>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container direction={"row"} spacing={2} sx={{
        display: {
          md: "flex",
          xs: "none"
        },
        justifyContent: {
          lg: "start",
          xs: "center"
        }
      }} data-sentry-element="Grid" data-sentry-source-file="NewsPreview.tsx">
          {newsPages.map((news, i) => {
          return <Grid item lg={4} md={4} sm={12} xs={12} key={i}>
                <PreviewNewsCard news={news} isRichText={true} />
              </Grid>;
        })}
        </Grid>
        <NewsCarousel block={block} data-sentry-element="NewsCarousel" data-sentry-source-file="NewsPreview.tsx" />
        <Box sx={{
        display: "flex",
        justifyContent: "center",
        mt: "38px"
      }} data-sentry-element="Box" data-sentry-source-file="NewsPreview.tsx">
          <Button component={NextLink} variant="contained" href={`${block.button.href}`} data-sentry-element="Button" data-sentry-source-file="NewsPreview.tsx">
            {block.button.title}
          </Button>
        </Box>
      </Container>
    </Box>;
};
export default NewsPreview;
export interface NewsCarouselProps {
  block: APITypes.Components.ContentBlocks.PreviewNewsBlock;
}
const NewsCarousel = ({
  block
}: NewsCarouselProps) => {
  return <Box sx={{
    backgroundColor: "#FFF",
    display: {
      md: "none",
      xs: "block"
    }
  }} data-sentry-element="Box" data-sentry-component="NewsCarousel" data-sentry-source-file="NewsPreview.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="NewsPreview.tsx">
        <Grid container justifyContent={"center"} data-sentry-element="Grid" data-sentry-source-file="NewsPreview.tsx">
          <Carousel swipe={true} autoPlay={false} sx={{
          width: "455px"
        }} navButtonsAlwaysInvisible={true} indicatorIconButtonProps={{
          style: {
            color: "grey",
            padding: "1px",
            marginTop: "40px"
          }
        }} activeIndicatorIconButtonProps={{
          style: {
            color: "black"
          }
        }} navButtonsProps={{
          style: {
            borderRadius: 0,
            backgroundColor: "transparent",
            color: "black"
          }
        }} data-sentry-element="Carousel" data-sentry-source-file="NewsPreview.tsx">
            {block.newsPages.map((news, i) => {
            return <PreviewNewsCard news={news} key={i} isRichText={false} />;
          })}
          </Carousel>
        </Grid>
      </Container>
    </Box>;
};
interface PreviewNewsCardProps {
  news: {
    title: string;
    description: string;
    image: APITypes.Components.Media;
    slug: string;
  };
  isRichText: boolean;
}
const PreviewNewsCard = ({
  news,
  isRichText
}: PreviewNewsCardProps) => {
  return <NextLink style={{
    height: "100%",
    fontSize: "18px",
    fontWeight: 700,
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
    flex: 1
  }} href={news.slug} data-sentry-element="NextLink" data-sentry-component="PreviewNewsCard" data-sentry-source-file="NewsPreview.tsx">
      <Card sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flex: 1,
      maxWidth: "455px",
      boxShadow: "none",
      borderRadius: 0,
      background: "#FAFAFA"
    }} data-sentry-element="Card" data-sentry-source-file="NewsPreview.tsx">
        <Stack data-sentry-element="Stack" data-sentry-source-file="NewsPreview.tsx">
          <Image alt={news.image?.name} image={news.image} style={{
          objectFit: "cover"
        }} layout="responsive" data-sentry-element="Image" data-sentry-source-file="NewsPreview.tsx" />
          <CardContent sx={{
          py: 4
        }} data-sentry-element="CardContent" data-sentry-source-file="NewsPreview.tsx">
            <Typography variant="h3" data-sentry-element="Typography" data-sentry-source-file="NewsPreview.tsx">{news.title}</Typography>
            <Typography component={"div"} color={"primary"} data-sentry-element="Typography" data-sentry-source-file="NewsPreview.tsx">
              {isRichText ? <Markdown content={news.description} /> : news.description}
            </Typography>
          </CardContent>
        </Stack>
        <Stack flexDirection={"row"} alignItems={"end"} justifyContent={"end"} data-sentry-element="Stack" data-sentry-source-file="NewsPreview.tsx">
          <Button sx={{
          px: 1,
          py: 0.5
        }} variant="contained" component={NextLink} href={news.slug} data-sentry-element="Button" data-sentry-source-file="NewsPreview.tsx">
            <ArrowForwardIcon sx={{
            p: 0,
            m: 0,
            width: "20px"
          }} fontSize="small" data-sentry-element="ArrowForwardIcon" data-sentry-source-file="NewsPreview.tsx" />
          </Button>
        </Stack>
      </Card>
    </NextLink>;
};