export enum ECluster {
  IT = "it-cluster",
  Consultants = "consultants-cluster",
  SelfStarters = "self-starters-cluster",
  Payroll = "payroll-cluster",
  Other = "other-cluster",
}

export const getClusterTranslation = (
  locale: string, // "fr-FR" | "nl",
  nonTranslatedCluster: string
) => {
  if (!nonTranslatedCluster) return "Unknown cluster";
  const cluster = nonTranslatedCluster?.toLowerCase();
  //This is something that could have been in the CMS, but this was way faster. Sorry.
  //These three are the same for both locales
  //self-starters-cluster
  if (cluster === "it") return "IT";
  if (cluster === "consultants") return "Consult";
  if (cluster === "payroll") return "Payroll";
  if (locale === "fr-FR") {
    if (cluster === "self starters") return "Starters & Indépendants";
    if (cluster === "other") return "Autre";
  }
  if (locale === "nl") {
    if (cluster === "self starters") return "Starters & Zelfstandigen";
    if (cluster === "other") return "Andere";
  }
};

export namespace APITypes {
  export interface DefaultAttributes {
    id: number;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    locale: string;
  }

  export interface Localization extends DefaultAttributes {
    title: string;
    slug: string;
  }

  export interface Page extends DefaultAttributes {
    title: string;
    slug: string;
    content: Components.ContentBlocks[];
    footer?: {
      content: (
        | Components.ContentBlocks.SubFooter
        | Components.ContentBlocks.Footer
      )[];
    };
    navigationBar?: {
      content: Components.ContentBlocks.NavigationBar[];
    };
    contact?: {
      content: Components.ContentBlocks.ContactITCluster[];
    };
    localizations: Localization[];
    seo: Components.SEO;
    movingBar: {
      title: string;
      content: Components.ContentBlocks.MovingBar[];
    };
  }

  export namespace Components {
    export interface SEO {
      metaTitle?: string;
      metaDescription?: string;
      keywords?: string;
      metaRobots?: string;
      structuredData?: Record<string, any>;
      metaViewport?: string;
      canonicalUrl?: string;
      metaImage?: Media;
      metaSocial?: Array<{
        socialNetwork?: "Twitter" | "Facebook" | "Instagram" | "Linkedin";
        title?: string;
        description?: string;
        image?: Media;
      }>;
    }
    export interface Media {
      name: string;
      alternativeText: string;
      caption: string;
      width: number;
      height: number;
      formats: any[];
      hash: string;
      ext: string;
      mime: string;
      size: number;
      url: string;
      previewUrl?: null | string;
      provider: string;
      provider_metadata: string | null;
      createdAt: string;
      updatedAt: string;
    }

    interface Link {
      name: string;
      href: string | null;
      icon?: Media;
    }

    export interface NavigationMenu {
      id: number;
      name: string;
      href: string | null;
      links: Link[];
      isChangeLocale?: boolean;
    }

    interface NavigationMenuFooter {
      id: number;
      title: string;
      subTitle: string;
      links: Link[];
      inputName?: string;
    }

    export interface Button {
      id: number;
      title: string;
      subTitle: string;
      hexColor: string;
      href: string;
    }

    interface SquareBlock {
      title: string;
      titleSize: "h1" | "h2";
      description: string;
      subTitle: string;
      hexBackgroundColor: string;
      button: Button;
    }

    interface TextBlock {
      title: string;
      //subTitle: string;
      description: string;
      hexTextColor: string;
      hexBackgroundColor: string;
      button?: FormSubmitButton;
    }

    interface VisionBlock {
      title: string;
      description: string;
    }

    interface MenuItem {
      title: string;
      description: string;
      mentorBlock: MentorBlock;
      hexTextColor: string;
      hexHoverTextColor: string;
      hexBackgroundColor: string;
    }

    interface BackgroundTextBlock {
      title: string;
      description: string;
      hexTextColor: string;
      hexHoverTextColor: string;
      hexBackgroundColor: string;
    }
    interface Title {
      title: string;
      description: string;
      hexTextColor: string;
      hexHoverTextColor: string;
      hexBackgroundColor: string;
    }

    export interface MentorBlock {
      image: Media;
      title: string;
      subTitle: string;
      description: string;
      href: string;
    }

    interface ListItem {
      title: string;
      subTitle: string;
      value?: string;
    }

    interface List {
      title: string;
      subTitle: string;
      listType: "NumberList" | "CheckList";
      listItem: ListItem[];
      hexHoverTextColor: string;
      hexBackgroundColor: string;
    }

    interface IconBlock {
      image: Media;
      title: string;
      description: string;
      hexLineColor: string;
      button: FormSubmitButton;
    }

    export interface FormCheckBox {
      Name: string;
      label: string;
    }

    export interface FormSubmitButton {
      title: string;
      subTitle: string;
      hexColor: string;
      href: string;
      successMessage: string;
      successDescription: string;
    }
    interface FormDescription {
      title: string;
      description: string;
      hexTextColor: string;
      hexBackgroundColor: string;
    }
    export interface ContactForm {
      firstNamePlaceholder: string;
      emailPlaceholder: string;
      phoneNumberPlaceholder: string;
      selectPlaceholder: string;
      selectOptions: ListItem[];
      checkBox: FormCheckBox[];
      submitButton: FormSubmitButton;
      cvPlaceholder: string;
    }

    export enum Size {
      h100 = "h100",
      h60 = "h60",
      h40 = "h40",
      h20 = "h20",
    }

    export interface ColumnImage {
      size: Size;
      image: Media;
    }

    export interface ColumnTextBlock extends TextBlock {
      size: Size;
    }

    export interface ColumnSocialBlock {
      size: Size;
      title: string;
      socialLink: Link[];
    }

    interface FormSelectField {
      selectPlaceholder: string;
      selectOptions: ListItem[];
    }

    export interface Job {
      id: string;
      jobsId: string;
      title: string;
      subTitle: string;
      location: string;
      compensationBenefits: string;
      jobDescription: string;
      jobRequirements: string;
      recruiter: string;
      contactInfo: string;
      companyInformation: string;
      profileTop: string;
      usp: string;
      slug: string;
      cluster: string;
      type: string;
      recruiterName: string;
      recruiterEmail: string;
      recruiterMobilePhone: string;
      recruiterPhone: string;
      recruiterLinkedIn: string;
      categories: { category: string }[];
    }

    export interface VacatureDetailForm {
      title: string;
      subTitle?: string;
      description?: string;
      namePlaceholder: string;
      emailAddressPlaceholder: string;
      birthDatePlaceholder: string;
      phonePlaceholder: string;
      attachmentSectionTitle: string;
      motivationPlaceholder: string;
      cvPlaceholder: string;
      attachmentPlaceholder: string;
      urlPlaceholder: string;
      submitButton: FormSubmitButton;
      checkBox: FormCheckBox[];
    }

    export interface QuizBlock {
      title: string;
      quizSteps: {
        question: string;
        negativeAnswer: {
          answer: string;
          score: number;
        };
        positiveAnswer: {
          answer: string;
          score: number;
        };
      }[];
      quizResults: {
        title: string;
        value: number;
        description: string;
      }[];
      button: Button;
    }

    export interface PreviewClusterCard {
      title: string;
      description: string;
      desktopImage: Media;
      mobileImage: Media;
      button: Button;
    }

    export interface Location {
      image: Media;
      id: string;
      description: string;
      longitude: number;
      latitude: number;
      subTitle: string;
      title: string;
      button: Button;
    }

    export interface IVideo {
      provider: string;
      providerUid: string;
      url: string;
    }

    export type ContentBlocks =
      | ContentBlocks.HeroITCluster
      | ContentBlocks.TrackITCluster
      | ContentBlocks.TrajectITCluster
      | ContentBlocks.SubTrajectITCluster
      | ContentBlocks.ExpectationsITCluster
      | ContentBlocks.ContactITCluster
      | ContentBlocks.NavigationBar
      | ContentBlocks.SubFooter
      | ContentBlocks.Footer
      | ContentBlocks.Hero
      | ContentBlocks.StepperFlow
      | ContentBlocks.TextBlockComponent
      | ContentBlocks.QuoteBlock
      | ContentBlocks.ImageBlock
      | ContentBlocks.ClusterHero
      | ContentBlocks.BlogBlock
      | ContentBlocks.NewsBlock
      | ContentBlocks.InternshipNews
      | ContentBlocks.CollapsibleList
      | ContentBlocks.InternshipForm
      | ContentBlocks.TextBlockButtonComponent
      | ContentBlocks.GridBlock
      | ContentBlocks.InfoIconBlocks
      | ContentBlocks.FaqBlock
      | ContentBlocks.JobCards
      | ContentBlocks.SpontaneousApplicationForm
      | ContentBlocks.JobsPreview
      | ContentBlocks.VacatureDetail
      | ContentBlocks.LandingPageContactFrom
      | ContentBlocks.LandingPageHero
      | ContentBlocks.ImageWithTextBlock
      | ContentBlocks.IconsWithTextBlock
      | ContentBlocks.QuotesCarousel
      | ContentBlocks.QuoteWithPicture
      | ContentBlocks.ClustersPreview
      | ContentBlocks.ImageWithContent
      | ContentBlocks.IconBlockWithButton
      | ContentBlocks.Locations
      | ContentBlocks.PreviewNewsBlock
      | ContentBlocks.RotatingTitleBlockWithButton
      | ContentBlocks.ColoredTextBlock
      | ContentBlocks.ConsultHero
      | ContentBlocks.MovingBar
      | ContentBlocks.ListBlock
      | ContentBlocks.ImageCarouselWithQuote
      | ContentBlocks.TroevenBlock
      | ContentBlocks.VideoCarousel
      | ContentBlocks.VisionBlock
      | ContentBlocks.StageCollapsibleList
      | ContentBlocks.InstagramBlock;

    export namespace ContentBlocks {
      export enum Types {
        HeroITCluster = "cluster-it-content.cluster-it-hero",
        TrackITCluster = "cluster-it-content.cluster-it-track",
        TrajectITCluster = "cluster-it-content.cluster-it-traject",
        SubTrajectITCluster = "cluster-it-content.cluster-it-traject-sub",
        ExpectationsITCluster = "cluster-it-content.cluster-it-expectations",
        ContactITCluster = "cluster-it-content.cluster-it-contact",
        NavigationBar = "content.navigation-bar",
        SubFooter = "content.sub-footer",
        Footer = "content.footer",
        Hero = "content.hero",
        StepperFlow = "content.stepper-flow",
        TextBlock = "content.text-block",
        QuoteBlock = "content.quote-block",
        ImageBlock = "content.image-block",
        BlogBlock = "content.blog",
        NewsBlock = "content.news",
        ClusterHero = "content.cluster-hero",
        InternshipNews = "content.internship-news",
        CollapsibleList = "content.collapsible-list",
        InternshipForm = "content.internship-form",
        TextBlockButton = "content.text-block-button",
        GridBlock = "content.grid",
        InfoIconBlocks = "content.info-icon-blocks",
        FaqBlock = "content.faq",
        JobsCards = "content.job-cards",
        ConsultantsForm = "content.consultants-form",
        JobsPreview = "content.jobs-preview",
        LandingPageContactFrom = "content.landing-page-form",
        LandingPageHero = "content.landing-page-hero",
        ImageWithTextBlock = "content.image-with-textblock",
        IconsWithTextBlock = "content.icons-with-textblock",
        QuotesCarousel = "content.quote-carousel",
        QuoteWithPicture = "content.quote-with-picture",
        ClustersPreview = "content.clusters-preview",
        ImageWithContent = "content.image-with-content",
        IconBlockWithButton = "content.icon-block-with-button",
        Locations = "content.locations",
        PreviewNewsBlock = "content.news-block",
        RotatingTitleBlockWithButton = "content.rotating-title-block-with-button",
        VacatureForm = "content.vacature-form",
        ColoredTextBlock = "content.colored-textblock",
        MovingBar = "content.moving-bar",
        NewsPageBlock = "content.news-page-block",
        ConsultHero = "content.consult-hero",
        ListBlock = "content.list-block",
        ImageCarouselWithQuote = "content.image-carousel-with-quote",
        TroevenBlock = "content.troeven-block",
        VideoCarousel = "content.video-carousel",
        VisionBlock = "content.vision-block",
        StageCollapsibleList = "content.stage-collapsible-list",
        InstagramBlock = "content.instagram-block",
      }

      interface BaseProps {
        id: number;
        __component: Types;
      }

      export interface NavigationBar extends BaseProps {
        logo: Media;
        hexBackgroundColor: string;
        hexTextColor: string;
        navigationMenu: NavigationMenu[];
      }

      export interface MovingBar extends BaseProps {
        coloredTitle: string;
        title: string;
        coloredTitleHexColor: string;
        button: FormSubmitButton;
      }

      export interface SubFooter extends BaseProps {
        logo: Media;
        description: string;
        hexBackgroundColor: string;
        hexTextColor: string;
        navigationMenu: NavigationMenuFooter[];
      }

      export interface Footer extends BaseProps {
        logo: Media;
        hexBackgroundColor: string;
        hexTextColor: string;
        navigationMenu: NavigationMenuFooter[];
      }

      export interface Hero extends BaseProps {
        backgroundImage: Media;
        title: string;
        titleAlignment: "right" | "left" | "center";
        backgroundColor: string;
      }

      export interface StepperFlow extends BaseProps {
        title: string;
        description: string;
        list: TextBlock[];
        button: Button;
      }
      export interface HeroITCluster extends BaseProps {
        hero: {
          heroImage: Media;
          heroSquareBlock: SquareBlock;
          hexBackgroundColor: string;
          forCluster: ECluster;
        };
        blueLink?: string;
      }

      export interface TrackITCluster extends BaseProps {
        textBlock: TextBlock;
        menuItem: MenuItem[];
        backgroundTextBlock: BackgroundTextBlock;
        title: Title;
        blueLink?: string;
      }

      export interface TrajectITCluster extends BaseProps {
        image: Media;
        list: List;
        hexBackgroundColor: string;
        hexTextColor: string;
      }

      export interface SubTrajectITCluster extends BaseProps {
        title: string;
        description: string;
        iconBlock: IconBlock[];
        hexBackgroundColor: string;
        hexTextColor: string;
      }

      export interface ExpectationsITCluster extends BaseProps {
        title: string;
        description: string;
        backgroundImage: Media;
        previewBlock: MentorBlock[];
        hexBackgroundColor: string;
        hexTextColor: string;
      }
      export interface ContactITCluster extends BaseProps {
        formDescription: FormDescription;
        form: ContactForm;
      }

      export interface ClusterHero extends BaseProps {
        heroImage: Media;
        heroSquareBlock: SquareBlock;
        hexBackgroundColor: string;
        forCluster: ECluster;
        blueLink?: string;
      }

      export interface TextBlockComponent extends BaseProps {
        title: string;
        subTitle: string;
        description: string;
        hexTitleColor?: string;
        hexTextColor: string;
        hexBackgroundColor: string;
      }

      export interface VisionBlock extends BaseProps {
        title: string;

        description: string;
      }

      export interface TextBlockButtonComponent extends BaseProps {
        textBlock: TextBlockComponent;
        buttons: Button[];
      }

      export interface RotatingTitleBlockWithButton extends BaseProps {
        button: Button;
        titleFixedPart: string;
        titleRotatingParts: {
          rotatingTitle: string;
          textColor: string;
        }[];
        subTitle: string;
        description: string;
        textColor: string;
        backgroundColor: string;
        image: Media;
      }
      export interface QuoteBlock extends BaseProps {
        author: string;
        position: string;
        quote: string;
        hexTextColor: string;
        hexBackgroundColor: string;
        backgroundImage: Media;
      }

      export interface ImageBlock extends BaseProps {
        image: Media;
        href: string;
      }

      export interface BlogBlock extends BaseProps {
        blog: {
          content: APITypes.Components.ContentBlocks[];
          contact: {
            content: APITypes.Components.ContentBlocks[];
          };
        };
      }
      export interface NewsBlock extends BaseProps {
        news: {
          content: APITypes.Components.ContentBlocks[];
          contact: {
            content: APITypes.Components.ContentBlocks[];
          };
        };
      }
      export interface InternshipNews extends BaseProps {
        backgroundImage: Media;
        title: string;
        button: Button;
      }
      export interface CollapsibleList extends BaseProps {
        title?: string;
        description?: string;
        list: TextBlock[];
      }

      export interface InternshipForm extends BaseProps {
        coursePlaceholder: string;
        description: string;
        emailAddressPlaceholder: string;
        endDatePlaceholder: string;
        motivationPlaceholder: string;
        namePlaceholder: string;
        personalInfoTitle: string;
        schoolPlaceholder: string;
        selectPlaceholder: string;
        stageTitle: string;
        startDatePlaceholder: string;
        subTitle: string;
        title: string;
        selectOptions: ListItem[];
        submitButton: FormSubmitButton;
        checkBox: FormCheckBox[];
      }

      export interface GridBlock extends BaseProps {
        column: {
          columnImage: ColumnImage[];
          columnTextBlock: ColumnTextBlock;
          columnSocialBlock: ColumnSocialBlock;
        }[];
      }
      export interface InfoIconBlocks extends BaseProps {
        direction: "row" | "column";
        alignment: "center" | "right" | "left";
        iconBlock: IconBlock[];
        textBlock: TextBlock;
      }

      export interface FaqBlock extends BaseProps {
        textBlock: TextBlock;
        faq: {
          collapsibleList: {
            list: TextBlock[];
          };
        };
      }

      export interface IOption {
        id: number;
        title: string;
        value: string;
      }

      export interface Filters {
        filterField: string;
        options: IOption[];
        subTitle: string;
        title: string;
      }

      export interface JobCards extends BaseProps {
        title: string;
        resultsTitle: string;
        subtitle: string;
        searchPlaceholder: string;
        filter: Filters[];
        noResultsTitle: string;
        noResultsContent?: string;
      }

      export interface SpontaneousApplicationForm extends BaseProps {
        title: string;
        formName: string;
        namePlaceholder: string;
        phoneNumberPlaceholder: string;
        emailAddressPlaceholder: string;
        motivationPlaceholder: string;
        selectField: FormSelectField[];
        submitButton: FormSubmitButton;
        checkBox: FormCheckBox[];
      }

      export interface JobsPreview extends BaseProps {
        title: string;
        button: Button;
        jobCardPreview: {
          subTitle: string;
          title: string;
          button: Button;
        };
        forCluster: ECluster;
        backgroundColor?: string;
        backgroundCardColor?: string;
      }

      export interface VacatureDetail extends BaseProps {
        uspTitle: string;
        uspSubTitle: string;
        functionDescriptionTitle: string;
        functionRequirementsTitle: string;
        offerTitle: string;
        offerSubTitle: string;
        offerButton: Button;
        heroImage: Media;
        heroImageIT: Media;
        heroImageConsult: Media;
        heroImagePayroll: Media;
        heroImageSelfStarters: Media;
        heroButton: Button;
        vacatureForm: VacatureDetailForm;
      }

      export interface LandingPageContactFrom extends BaseProps {
        title: string;
        description: string;
        namePlaceholder: string;
        emailAddressPlaceholder: string;
        messagePlaceholder: string;
        checkBox: FormCheckBox[];
        submitButton: FormSubmitButton;
      }

      export interface LandingPageHero extends BaseProps {
        heroImage: Media;
        heroTitle: string;
        heroSubTitle: string;
        heroSquareBlock: SquareBlock;
        quizBlock: QuizBlock;
      }

      export interface ImageWithTextBlock extends BaseProps {
        backgroundImage: Media;
        title: string;
        content?: string;
        textColor?: string;
        textBlockPosition: "left" | "center" | "right";
        textblockBackgroundColor?: string;
        backgroundColor?: string;
        buttons?: Button[];
      }

      export interface ColoredTextBlock extends BaseProps {
        title: string;
        subTitle?: string;
        content?: string;
        textColor?: string;
        textBlockPosition: "left" | "center" | "right";
        textblockBackgroundColor?: string;
        backgroundColor?: string;
      }
      export interface IconsWithTextBlock extends BaseProps {
        title: string;
        description: string;
        iconBlockTitle: string;
        iconBlock: IconBlock[];
        layout: "regular" | "two columns";
      }

      export interface QuoteWithPicture extends BaseProps {
        personName?: string;
        quote: string;
        image: Media;
        textColor?: string;
        backgroundColor?: string;
      }
      export interface QuotesCarousel extends BaseProps {
        title?: string;
        quotes: QuoteWithPicture[];
      }

      export interface ClustersPreview extends BaseProps {
        title: string;
        previewClusterCards: PreviewClusterCard[];
      }
      export interface ImageWithContent extends BaseProps {
        title: string;
        subTitle: string;
        image: Media;
        content: string;
        backgroundColor?: string;
        imagePosition: "left" | "right";
      }
      export interface IconBlockWithButton extends BaseProps {
        title: string;
        iconBlock: IconBlock[];
        button: Button;
      }

      export interface Locations extends BaseProps {
        locations: Location[];
      }
      export interface PreviewNewsBlock extends BaseProps {
        title: string;
        socialLinksTitle: string;
        socialLinks: Link[];
        newsPages: {
          title: string;
          description: string;
          image: Media;
          slug: string;
        }[];
        button: Button;
      }

      export interface VacatureForm extends BaseProps {
        vacatureForm: VacatureDetailForm;
      }

      export interface NewsPageBlock extends BaseProps {
        newsPages: {
          title: string;
          description: string;
          image: Media;
          slug: string;
        }[];
      }
      export interface ConsultHero extends BaseProps {
        title: string;
        carouselItems: { image: Media; text: string }[];
        heroSquareBlock: SquareBlock;
        blueLink?: string;
      }

      export interface ListBlock extends BaseProps {
        list: {
          title: string;
          listItem: { title: string; subTitle: string }[];
        };
      }
      export interface ImageCarouselWithQuote extends BaseProps {
        title: string;
        carouselItems: {
          image: Media;
          quote: string;
          author: string;
        }[];
      }

      export interface TroevenBlock extends BaseProps {
        title: string;
        blocks: {
          image: Media;
          content: string;
          title: string;
        }[];
      }

      export interface VideoCarousel extends BaseProps {
        videos: {
          video: IVideo;
        }[];
        titleText: {
          title: string;
          text: string;
        };
      }

      export interface StageCollapsibleList extends BaseProps {
        title?: string;
        description?: string;
        list: TextBlock[];
      }
      export interface InstagramBlock extends BaseProps {
        title: string;
        description: string;
      }
    }
  }
}
