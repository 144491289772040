import { colors } from "@components/Theme/colors";
import { ATTACHMENT_BUTTON_HOLDER_STYLE, CV_BUTTON_HOLDER_STYLE, textFieldStyle } from "@components/VacatureDetail/VacatureDetailContactBlock/ContactForm.styles";
import { validationSchema } from "@components/VacatureDetail/VacatureDetailContactBlock/ContactForm.util";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import NearMeIcon from "@mui/icons-material/NearMe";
import StopIcon from "@mui/icons-material/Stop";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormHelperText, Grid, InputLabel, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Field, Formik, useFormik } from "formik";
import { useRouter } from "next/router";
import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { APITypes } from "utils/api.types";
import { Slack } from "utils/slackIntegration";
import { Markdown } from "@components/Markdown/MarkdownComponent";
import FormSentMessage from "./components/FormSentMessage";
const FIELD_KEY_CV = "cxsrec__cxscandidate__c.cxsrec__last_cv__c";
const FIELD_KEY_ATTACHMENT = "cxsrec__cxscandidate__c.cxsrec__last_letter__c";
const FIELD_KEY_FIRST_NAME = "cxsrec__cxscandidate__c.cxsrec__first_name__c";
const FIELD_KEY_LAST_NAME = "cxsrec__cxscandidate__c.cxsrec__last_name__c";
const FIELD_KEY_EMAIL = "cxsrec__cxscandidate__c.cxsrec__e_mail_address__c";
const FIELD_KEY_BIRTH = "cxsrec__cxscandidate__c.cxsrec__date_of_birth__c";
const FIELD_KEY_MOBILE = "cxsrec__cxscandidate__c.cxsrec__mobile_phone__c";
const FIELD_KEY_LINKED = "cxsrec__cxscandidate__c.cxsrec__linked_in_url__c";
enum EConnexysMediaChannelIds {
  French = "a0nw000000DEYcQAAX",
  Dutch = "a0nw0000007zFMkAAM",
}
interface ContactFormProps {
  form: APITypes.Components.VacatureDetailForm;
  isScriptLoaded: boolean;
  jobsId: string;
}
const post = (windowRef: MutableRefObject<any>, jobId: string, data: Record<string, any>) => {
  try {
    Slack.notify(`POST: Trying to post data`, jobId, data);
    windowRef.current.cxsForm.post();
  } catch (e) {
    Slack.notify(`POST (TRY / CATCH): SOMETHING WENT WRONG: ${e}`, jobId, JSON.stringify(e));
    throw new Error(`sending an application failed with the following error ${e}`);
  }
};
const ContactForm = ({
  form,
  jobsId,
  isScriptLoaded
}: ContactFormProps) => {
  const router = useRouter();
  const {
    namePlaceholder,
    emailAddressPlaceholder,
    birthDatePlaceholder,
    phonePlaceholder,
    attachmentSectionTitle,
    cvPlaceholder,
    attachmentPlaceholder,
    urlPlaceholder,
    submitButton,
    checkBox,
    title,
    subTitle,
    description
  } = form;
  const [isFormReady, setFormReady] = useState(false);
  const [isFormSent, setFormSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isCVUploaded, setCVUploaded] = useState(false);
  const [isAttachmentUploaded, setAttachmentUploaded] = useState(false);
  const [cvUploadError, setCVUploadError] = useState("");
  const [extraUploadError, setExtraUploadError] = useState("");
  const attachmentFieldReady = useRef(false);
  const resetFormFunction = useRef<any>(null);
  const windowRef = useRef<any>(null);
  const hasErrorsLeft = useRef<{
    [x: string]: boolean | string;
  } | null>(null);
  useEffect(() => {
    if (window && !windowRef.current) windowRef.current = window;
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      birthDate: "",
      number: "",
      cv: false,
      attachment: false,
      url: "",
      privacy: false
    },
    validationSchema: validationSchema,
    onSubmit: async (values, {
      resetForm
    }) => {
      try {
        console.log("hasErrorsLeft.current:", hasErrorsLeft.current);
        if (hasErrorsLeft.current && Object.values(hasErrorsLeft.current).some(v => typeof v === "string")) {
          Slack.notify("FORMIK SUBMIT: There are still errors left!", jobsId, {
            ...values,
            ...hasErrorsLeft.current
          }, false);
          if (hasErrorsLeft.current[FIELD_KEY_BIRTH] !== false) {
            Slack.notify("THE ERROR WE HAVE LEFT IS A BIRTHDAY ERROR! Setting it to a birthday we know is okay, just to make the submit work!", jobsId, {});
            //Post anyway with another date
            windowRef.current.cxsForm.setFieldValue(FIELD_KEY_BIRTH, "1990-01-01");
            console.log("windowRef.current.cxsForm:", windowRef.current.cxsForm);
            post(windowRef, jobsId, values);
          }
          return;
        } else {
          resetFormFunction.current = resetForm;
          // @ts-ignore
          window.dataLayer = window.dataLayer || [];
          // @ts-ignore
          window.dataLayer.push({
            event: "submitbuttonjobs"
          });
          post(windowRef, jobsId, values);
        }
      } catch (e: any) {
        Slack.notify("ERROR IN FORMIK SUBMIT!", jobsId, {
          ...values,
          error: JSON.stringify(e)
        }, false);
        throw new Error(`An error happened in the formik onSubmit function ${e}`);
      }
    }
  });
  const handleCvUpload = useCallback((status: string) => {
    let result = false;
    if (status === "ok") {
      formik.setFieldValue("cv", true);
      setCVUploadError("");
      result = true;
    } else if (status === "error") {
      const data = windowRef.current.cxsForm.getFieldData(FIELD_KEY_CV);
      formik.setFieldValue("cv", false);
      formik.setTouched({
        ...formik.touched,
        ["cv"]: true
      });
      formik.setFieldError("cv", data.errorMessage);
      setCVUploadError(data.errorMessage);
      result = false;
    }
    setCVUploaded(result);
  }, [formik]);
  const handleAttachmentUpload = useCallback((status: string) => {
    let result = false;
    if (status === "ok") {
      formik.setFieldValue("attachment", true);
      setExtraUploadError("");
      result = true;
    } else if (status === "error") {
      const data = windowRef.current.cxsForm.getFieldData(FIELD_KEY_ATTACHMENT);
      formik.setFieldValue("attachment", false);
      formik.setTouched({
        ...formik.touched,
        ["attachment"]: true
      });
      formik.setFieldError("attachment", data.errorMessage);
      setExtraUploadError(data.errorMessage);
      result = false;
    }
    setAttachmentUploaded(result);
  }, [formik]);
  const formInit = (isFirstTime: boolean) => {
    if (!attachmentFieldReady.current) {
      const source = document.getElementById("cxsFieldContainer_16");
      const destination = document.getElementById("attachment_button_iframe");
      if (source != null && destination != null) {
        attachmentFieldReady.current = true;
        destination.appendChild(source);
      }
      setFormReady(true);
    }
  };
  useEffect(() => {
    if (windowRef.current && windowRef.current.cxsForm != null && isScriptLoaded && router.isReady) {
      windowRef.current.cxsForm.init({
        target: "#cxsFormHolder",
        server: process.env.NEXT_PUBLIC_CONNEXYS_URL,
        jobId: jobsId,
        placeholders: false,
        errorHeader: true,
        onFileUpload: (status: string, fieldKey: string) => {
          if (fieldKey === FIELD_KEY_ATTACHMENT) {
            handleAttachmentUpload(status);
          } else if (fieldKey === FIELD_KEY_CV) {
            handleCvUpload(status);
          }
        },
        onInit: formInit,
        onBeforeSubmit: (formData: string) => {
          try {
            setIsSending(true);
            Slack.notify(`BEFORE SUBMIT: Someone is trying to submit this data! ${hasErrorsLeft.current ?? "THERE ARE ERRRORS LEFT!"},`, jobsId, {
              formData,
              errors: hasErrorsLeft.current ? JSON.stringify(Object.values(hasErrorsLeft.current)) : []
            }, hasErrorsLeft.current !== null && Object.values(hasErrorsLeft.current).some(v => typeof v === "string"));
          } catch (e) {
            throw new Error(`There was an error in the beforeSubmit Function: ${e}`);
          }
        },
        onAfterSubmit: (success: boolean, formData: string) => {
          Slack.notify("AFTER SUBMIT: Successfully sent data to Connexys!", jobsId, formData, success);
          if (success) {
            setTimeout(() => {
              setIsSending(false);
              setFormSent(true);
              setCVUploaded(false);
              setAttachmentUploaded(false);
              if (resetFormFunction.current) {
                resetFormFunction.current();
              }
            }, 300);
          } else {
            setIsSending(false);
            throw new Error(`there was an error with the form submission, onAfterSubmit got '${success}' for the success boolean. formData: ${formData}`);
          }
          return false;
        },
        onAfterValidation: (jsonFieldInfo: any) => {
          if (jsonFieldInfo && jsonFieldInfo.error !== undefined) {
            hasErrorsLeft.current = {
              ...hasErrorsLeft.current,
              [jsonFieldInfo.key]: jsonFieldInfo.error ? jsonFieldInfo.errorMessage : false
            };
          }
          if (jsonFieldInfo.key === FIELD_KEY_BIRTH) {
            try {
              const date = new Date(jsonFieldInfo.value);
              console.log("Date:", date);
            } catch (e) {
              console.log(`Error in parsing date (date value was: ${jsonFieldInfo.value}):`, e);
              Slack.notify(`DATE ERROR: Error in parsing date (date value was: ${jsonFieldInfo.value}):`, jobsId, JSON.stringify(e), false);
            }
          }
        },
        lang: router.locale === "nl" ? "nl_NL" : "fr",
        mediaChannelId: router.locale === "nl" ? EConnexysMediaChannelIds.Dutch : EConnexysMediaChannelIds.French
      });
    }
  }, [isScriptLoaded, jobsId, router.locale, router.isReady, handleAttachmentUpload, handleCvUpload]);
  if (isFormSent) {
    return <FormSentMessage submitButton={submitButton} />;
  }
  if (isSending) {
    return <CircularProgress />;
  }
  return <div data-sentry-component="ContactForm" data-sentry-source-file="ContactForm.tsx">
      {!isFormReady && <Typography variant="h5" sx={{
      color: colors.primary.main
    }}>
          Formulier wordt geladen...
        </Typography>}
      {isFormReady && <Formik initialValues={{
      name: "",
      email: "",
      birthDate: "",
      number: "",
      cv: false,
      attachment: false,
      url: "",
      privacy: false
    }} onSubmit={() => {}}>
          <form onSubmit={formik.handleSubmit}>
            <script dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MCMJ688');`
        }} />
            <Grid container direction="row" rowSpacing={1} columnSpacing={2}>
              <Grid item xs={12}>
                <Stack pb={4}>
                  {subTitle != null && <Typography color={colors.secondary.main} sx={{
                fontWeight: 600
              }}>
                      {subTitle}
                    </Typography>}
                  <Typography variant="h2">{title}</Typography>
                  {description != null && <Typography sx={{
                pt: 4
              }}>{description}</Typography>}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel>{namePlaceholder} *</InputLabel>
                <TextField fullWidth id="name" name="name" value={formik.values.name} onChange={event => {
              const [firstName, ...lastName] = event.target.value.split(" ");
              windowRef.current.cxsForm.setFieldValue(FIELD_KEY_FIRST_NAME, firstName);
              // Quick fix to get the "double names in connexys" issue fixed
              windowRef.current.cxsForm.setFieldValue(FIELD_KEY_LAST_NAME, lastName.length > 0 ? lastName.join(" ") : firstName);
              formik.handleChange(event);
            }} error={formik.touched.name && Boolean(formik.errors.name)} helperText={formik.touched.name && formik.errors.name} />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel>{emailAddressPlaceholder} *</InputLabel>
                <TextField fullWidth id="email" name="email" value={formik.values.email} onChange={event => {
              windowRef.current.cxsForm.setFieldValue(FIELD_KEY_EMAIL, event.target.value);
              formik.handleChange(event);
            }} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel>{birthDatePlaceholder} *</InputLabel>
                <DatePicker maxDate={dayjs(new Date().setFullYear(new Date().getFullYear() - 10)) as any} minDate={dayjs(new Date().setFullYear(new Date().getFullYear() - 100)) as any} format="YYYY-MM-DD" slotProps={{
              textField: {
                placeholder: "DD-MM-YYYY",
                fullWidth: true,
                name: "birthDate",
                helperText: formik.touched.birthDate && formik.errors.birthDate,
                error: formik.touched.birthDate && Boolean(formik.errors.birthDate),
                sx: {
                  input: {
                    "&::placeholder": {
                      color: "#093950"
                    }
                  },
                  "& .MuiInputBase-root": {
                    background: "white"
                  },
                  "& .MuiFormHelperText-root": {
                    backgroundColor: "transparent"
                  },
                  "& .MuiInputBase-adornedEnd": {
                    backgroundColor: "white"
                  }
                }
              }
            }} onChange={(val: string | null) => {
              formik.setFieldValue("birthDate", dayjs(val).format("YYYY-MM-DD"));
              if (val != null) {
                try {
                  windowRef.current.cxsForm.setFieldValue(FIELD_KEY_BIRTH, dayjs(val).format("YYYY-MM-DD"));
                } catch (e) {
                  console.log("Error in setting birthdate to connexys form:", e);
                }
              }
            }} />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel>{phonePlaceholder} *</InputLabel>
                <TextField fullWidth id="number" name="number" value={formik.values.number} onChange={event => {
              windowRef.current.cxsForm.setFieldValue(FIELD_KEY_MOBILE, event.target.value);
              formik.handleChange(event);
            }} error={formik.touched.number && Boolean(formik.errors.number)} helperText={formik.touched.number && formik.errors.number} />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel>{cvPlaceholder} *</InputLabel>
                <Stack direction="row" gap={1}>
                  <Stack direction="column" width={"100%"}>
                    <Button fullWidth variant="contained" component="label" sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  position: "relative"
                }} endIcon={isCVUploaded ? <CheckIcon /> : <AddIcon />}>
                      <Box sx={CV_BUTTON_HOLDER_STYLE} id="cxsFormHolder" />

                      {!isCVUploaded ? cvPlaceholder : ""}
                    </Button>
                    <FormHelperText sx={{
                  color: "red",
                  margin: ".5rem 0 0 0"
                }}>
                      {cvUploadError.length ? cvUploadError : formik.touched.cv && formik.errors.cv}
                    </FormHelperText>
                  </Stack>
                  <Stack direction="column" width={"100%"}>
                    <Button id="attachment_button" fullWidth variant="contained" component="label" sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  position: "relative"
                }} endIcon={isAttachmentUploaded ? <CheckIcon /> : <AddIcon />}>
                      <Box sx={ATTACHMENT_BUTTON_HOLDER_STYLE} id="attachment_button_iframe" />
                      {!isCVUploaded ? attachmentPlaceholder : ""}
                    </Button>
                    <FormHelperText sx={{
                  color: "red"
                }}>
                      {extraUploadError.length ? extraUploadError : null}
                    </FormHelperText>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel>{urlPlaceholder}</InputLabel>
                <TextField sx={textFieldStyle} fullWidth id="url" name="url" value={formik.values.url} onChange={event => {
              windowRef.current.cxsForm.setFieldValue(FIELD_KEY_LINKED, event.target.value);
              formik.handleChange(event);
            }} error={formik.touched.url && Boolean(formik.errors.url)} helperText={formik.touched.url && formik.errors.url} />
              </Grid>

              <Grid item xs={12}>
                <Grid container direction={"row"} alignItems={"center"}>
                  <Grid item xs={12}>
                    {checkBox.map((checkbox, i) => <Grid item xs={12} key={i}>
                        <Field type="checkbox" name={"privacy"} as={FormControlLabel} control={<Checkbox checkedIcon={<StopIcon sx={{
                    fontSize: "24px",
                    border: 0.2,
                    borderRadius: 0.5,
                    color: colors.primary.main,
                    backgroundColor: "white",
                    borderColor: "white"
                  }} />} />} checked={formik.values.privacy} label={checkbox.label ? <Markdown content={checkbox.label} /> : checkbox.Name} onChange={(event: any) => {
                    windowRef.current.cxsForm.setFieldValue("form.privacystatement", event.target.value);
                    formik.handleChange(event);
                  }} />
                        <FormHelperText sx={{
                    color: "red",
                    margin: "-0.5rem 0 0 0"
                  }}>
                          {formik.touched.privacy && formik.errors.privacy}
                        </FormHelperText>
                      </Grid>)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button size="large" variant="contained" type="submit" id="submitbuttonjobs" color="green" endIcon={<NearMeIcon fontSize="small" />}>
                  {form.submitButton.title}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Formik>}
    </div>;
};
export default ContactForm;