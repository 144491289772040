import { colors } from "@components/Theme/colors";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Container, Grid, IconButton, InputAdornment, Pagination, Stack, TextField, Typography } from "@mui/material";
import { useRouter } from "next/router";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { APITypes } from "utils/api.types";
import { fetchPositions } from "utils/fetchingFunctions";
import Filter from "../Filters/Filter";
import { LocationsMappings } from "../Filters/filter.util";
import { NotFoundBlock } from "../Filters/NotFoundBlock";
import SelectedChips from "../SelectedChips/SelectedChips";
import { JobCard } from "./JobCard";
import { paginationStyle, textFieldStyle } from "./JobsOverview.styles";
const DUTCH_INTERNSHIP_ID = "a0tw0000004PadFAAS";
const FRANCE_INTERNSHIP_ID = "a0t6900000CHn9MAAT";
const CATEGORIES_TITLE_NL = "Categorieën";
const CATEGORIES_TITLE_FR = "Catégories";
const MOBILE_LOCATION = "mobile";
export interface JobCardsProps {
  block: APITypes.Components.ContentBlocks.JobCards;
  slug: string;
  localizations: APITypes.Localization[];
}
export enum EJobFilters {
  Team = "cluster",
  Location = "location",
  Type = "type",
  Categories = "categories",
}
const defaultPagination = {
  page: 1,
  pageSize: 12,
  pageCount: 0,
  total: 0
};
export const JobsOverview = ({
  block,
  slug
}: JobCardsProps) => {
  const {
    filter,
    title,
    searchPlaceholder,
    resultsTitle
  } = block;
  const router = useRouter();
  const [pagination, setPagination] = useState<{
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
  }>(defaultPagination);
  const [positions, setPositions] = useState<APITypes.Components.Job[]>([]);
  const [searchField, setSearchField] = useState<string | undefined>();
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedClusters, setSelectedClusters] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [initializedFilters, setInitializedFilters] = useState<boolean>(false);
  const [filterWithCategories, setFilterWithCategories] = useState<APITypes.Components.ContentBlocks.Filters[]>([]);
  const {
    query
  } = router;
  const updateURLParams = useCallback((newParams: {
    [key: string]: string[];
  }) => {
    const updatedQuery: {
      [key: string]: string;
    } = {};
    Object.keys(newParams).forEach(key => {
      const values = newParams[key];
      if (values.length > 0) {
        updatedQuery[key] = values.join(",");
      } else {
        delete updatedQuery[key];
      }
    });
    const newQueryString = qs.stringify(updatedQuery, {
      skipNulls: true
    });
    const newUrl = `${window.location.pathname}?${newQueryString}`;
    window.history.replaceState(null, "", newUrl);
  }, [router.query]);
  useEffect(() => {
    if (query) {
      if (query[EJobFilters.Team]) {
        const clusterArray = Array.isArray(query[EJobFilters.Team]) ? query[EJobFilters.Team] : [query[EJobFilters.Team]];
        setSelectedClusters([...clusterArray]);
      }
      if (query[EJobFilters.Location]) {
        const locationArray = Array.isArray(query[EJobFilters.Location]) ? query[EJobFilters.Location] : [query[EJobFilters.Location]];
        setSelectedLocations([...locationArray]);
      }
      if (query[EJobFilters.Type]) {
        const typeArray = Array.isArray(query[EJobFilters.Type]) ? query[EJobFilters.Type] : [query[EJobFilters.Type]];
        setSelectedTypes([...typeArray]);
      }
      if (query[EJobFilters.Categories]) {
        const categoryArray = Array.isArray(query[EJobFilters.Categories]) ? query[EJobFilters.Categories] : [query[EJobFilters.Categories]];
        setSelectedCategories([...categoryArray]);
      }
    }
    if (router.isReady) {
      setInitializedFilters(true);
    }
  }, [query, router.isReady, router.query]);
  const fetchData = useCallback(async (query: string, page: number) => {
    const res = await fetchPositions(query, page);
    return res;
  }, []);
  useEffect(() => {
    const fetcher = async (query: string) => {
      const result = await fetchData(query, pagination.page);
      setPositions(result.data ?? []);
      setPagination(result.meta);
    };
    let query = "";
    let filters: any = {};
    if (initializedFilters) {
      let locationsFilter = {};
      let clustersFilter = {};
      let typesFilter = {};
      let categoriesFilter = {};
      let isMobileFilter = {};
      if (selectedLocations && selectedLocations.length > 0) {
        // Special treatment for "location" filter -> Values are first mapped to an other
        // array of possible values (brussel => [brussel, brussels, bruxelles])
        const newLocations = selectedLocations.reduce((all, location) => {
          if (location) {
            const mapped = LocationsMappings.get(location);
            if (mapped) {
              all = [...all, ...mapped];
            }
          }
          return all;
        }, selectedLocations as string[]);
        locationsFilter = {
          $or: newLocations.map(location => {
            if (location !== MOBILE_LOCATION) {
              return {
                ["location"]: {
                  $containsi: location
                }
              };
            } else {
              return {
                ["isMobile"]: {
                  $eq: true
                }
              };
            }
          })
        };
      }
      if (selectedClusters && selectedClusters.length > 0) {
        clustersFilter = {
          ["cluster"]: {
            $in: selectedClusters
          }
        };
      }
      if (selectedTypes && selectedTypes.length > 0) {
        typesFilter = {
          ["type"]: {
            $in: selectedTypes
          }
        };
      }
      if (selectedCategories && selectedCategories.length > 0) {
        typesFilter = {
          ["categories"]: {
            ["category"]: {
              $in: selectedCategories
            }
          }
        };
      }
      filters = {
        ...filters,
        ...locationsFilter,
        ...isMobileFilter,
        ...clustersFilter,
        ...typesFilter,
        ...categoriesFilter
      };
      if (searchField) {
        filters = {
          ...filters,
          $and: [locationsFilter, {
            $or: [{
              title: {
                $containsi: searchField
              }
            }, {
              profileTop: {
                $containsi: searchField
              }
            }]
          }]
        };
      }
      const params: any = {
        filters: selectedClusters && selectedClusters.length ? {
          $or: [{
            jobsId: router.locale === "nl" ? DUTCH_INTERNSHIP_ID : FRANCE_INTERNSHIP_ID
          }, filters]
        } : filters,
        locale: [router.locale]
      };

      /* const limit = undefined;
            if (limit) params.limit = limit; */

      query = qs.stringify(params, {
        encodeValuesOnly: true
      });
    }
    if (initializedFilters) {
      fetcher(query);
      updateURLParams({
        cluster: selectedClusters,
        location: selectedLocations,
        type: selectedTypes,
        categories: selectedCategories
      });
    }
  }, [fetchData, initializedFilters, selectedClusters, selectedLocations, selectedTypes, router.locale, searchField, selectedCategories, pagination.page, updateURLParams]);
  useEffect(() => {
    const clustersFromURL = query.cluster?.toString().split(",") || [];
    const locationsFromURL = query.location?.toString().split(",") || [];
    const typesFromURL = query.type?.toString().split(",") || [];
    const categoriesFromURL = query.categories?.toString().split(",") || [];
    setSelectedClusters(clustersFromURL);
    setSelectedLocations(locationsFromURL);
    setSelectedTypes(typesFromURL);
    setSelectedCategories(categoriesFromURL);
  }, [query]);
  useEffect(() => {
    const fetcher = async () => {
      const params: any = {
        locale: [router.locale]
      };
      const query = qs.stringify(params, {
        encodeValuesOnly: true
      });
      const result: {
        data: APITypes.Components.Job[];
      } = await fetchData(query, 1);
      const allCategories = (result?.data || []).map(position => position.categories).flat().map(item => item.category);
      const uniqueCategories = [...new Set(allCategories)];
      setFilterWithCategories([{
        title: router.locale === "nl" ? CATEGORIES_TITLE_NL : CATEGORIES_TITLE_FR,
        subTitle: "",
        filterField: "categories",
        options: uniqueCategories.map((category, i) => ({
          id: i,
          title: category,
          value: category
        }))
      }, ...filter]);
    };
    fetcher();
  }, [fetchData, filter, router.locale]);
  const addOrRemove = (fields: string[], isChecked: boolean, value: string) => {
    let _fields = [...fields];
    if (isChecked) {
      //Add to filter
      _fields = [..._fields, value];
    } else {
      // remove from filter
      const index = _fields.findIndex(selected => selected === value);
      if (index !== undefined) {
        _fields.splice(index, 1);
      }
    }
    return _fields;
  };
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, filterField: string) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    let newFields: string[] = [];
    switch (filterField) {
      case "cluster":
        newFields = addOrRemove(selectedClusters, isChecked, value);
        setSelectedClusters([...newFields]);
        break;
      case "location":
        newFields = addOrRemove(selectedLocations, isChecked, value);
        setSelectedLocations([...newFields]);
        break;
      case "type":
        newFields = addOrRemove(selectedTypes, isChecked, value);
        setSelectedTypes([...newFields]);
        break;
      case "categories":
        newFields = addOrRemove(selectedCategories, isChecked, value);
        setSelectedCategories([...newFields]);
        break;
    }
    setPagination(defaultPagination);
  }, [selectedClusters, selectedLocations, selectedTypes, selectedCategories]);
  const handleRemoveChip = (value: string, category: string) => {
    switch (category) {
      case "cluster":
        setSelectedClusters(prev => prev.filter(item => item !== value));
        break;
      case "location":
        setSelectedLocations(prev => prev.filter(item => item !== value));
        break;
      case "type":
        setSelectedTypes(prev => prev.filter(item => item !== value));
        break;
      case "categories":
        setSelectedCategories(prev => prev.filter(item => item !== value));
        break;
      default:
        break;
    }
    setPagination(defaultPagination);
  };
  const handleChangePage = (event: React.ChangeEvent<unknown> | null, newPage: number) => {
    setPagination({
      ...pagination,
      page: newPage
    });
  };
  return <Box sx={{
    backgroundColor: colors.greyColor.main
  }} py={12} data-sentry-element="Box" data-sentry-component="JobsOverview" data-sentry-source-file="JobsOverview.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="JobsOverview.tsx">
        <Grid container alignItems="start" direction="column" justifyContent="center" spacing={2} gap={8} data-sentry-element="Grid" data-sentry-source-file="JobsOverview.tsx">
          <Container data-sentry-element="Container" data-sentry-source-file="JobsOverview.tsx">
            <Grid container justifyContent={"center"} alignItems={"center"} sx={{
            width: "100%"
          }} data-sentry-element="Grid" data-sentry-source-file="JobsOverview.tsx">
              <TextField fullWidth placeholder={searchPlaceholder} sx={textFieldStyle} onChange={({
              target
            }) => {
              setSearchField(target.value);
            }} value={searchField} InputProps={{
              endAdornment: <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
            }} data-sentry-element="TextField" data-sentry-source-file="JobsOverview.tsx" />
              <Stack flexDirection={{
              xs: "column",
              md: "row"
            }} justifyContent={"center"} alignItems={"center"} gap={2} sx={{
              width: "100%"
            }} data-sentry-element="Stack" data-sentry-source-file="JobsOverview.tsx">
                {filterWithCategories.map((filter, i) => {
                let checkedOptions: string[] = [];
                switch (filter.filterField) {
                  case "cluster":
                    checkedOptions = selectedClusters;
                    break;
                  case "location":
                    checkedOptions = selectedLocations;
                    break;
                  case "type":
                    checkedOptions = selectedTypes;
                    break;
                  case "categories":
                    checkedOptions = selectedCategories;
                    break;
                  default:
                    checkedOptions = [];
                }
                return <Filter title={filter.title} key={i} options={filter.options} filterField={filter.filterField} handleChange={handleChange} checkedOptions={checkedOptions} />;
              })}
              </Stack>
            </Grid>
          </Container>
          <Grid item sx={{
          marginTop: {
            xs: 0,
            md: -2
          }
        }} container alignItems="stretch" direction="row" xs={12} spacing={2} data-sentry-element="Grid" data-sentry-source-file="JobsOverview.tsx">
            <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="JobsOverview.tsx">
              <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="JobsOverview.tsx">
                {`${pagination ? pagination.total : 0} ${resultsTitle}`}
              </Typography>
              <SelectedChips selectedClusters={selectedClusters} selectedLocations={selectedLocations} selectedTypes={selectedTypes} selectedCategories={selectedCategories} handleRemoveChip={handleRemoveChip} data-sentry-element="SelectedChips" data-sentry-source-file="JobsOverview.tsx" />
            </Grid>
            {(positions || []).map((position, i) => <Grid key={i} item xs={12} md={4}>
                <JobCard position={position} slug={slug} />
              </Grid>)}
            {(!positions || positions && positions.length === 0) && <NotFoundBlock title={block.noResultsTitle} content={block.noResultsContent} />}
          </Grid>
          <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"} sx={{
          width: "100%"
        }} data-sentry-element="Stack" data-sentry-source-file="JobsOverview.tsx">
            <Pagination page={pagination.page} onChange={handleChangePage} count={pagination.pageCount} shape="rounded" sx={paginationStyle} data-sentry-element="Pagination" data-sentry-source-file="JobsOverview.tsx" />
          </Stack>
        </Grid>
      </Container>
    </Box>;
};