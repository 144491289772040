import { Markdown } from "@components/Markdown/MarkdownComponent";
import StopIcon from "@mui/icons-material/Stop";
import { Box, Button, Checkbox, Container, FormControlLabel, FormHelperText, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Field, Formik, useFormik } from "formik";
import { useRouter } from "next/router";
import { useState } from "react";
import { APITypes } from "utils/api.types";
import { submitForm } from "utils/fetchingFunctions";
import * as Yup from "yup";
export interface ConsultantsContactFormProps {
  block: APITypes.Components.ContentBlocks.SpontaneousApplicationForm;
}
const SpontaneousApplicationContactForm = ({
  block
}: ConsultantsContactFormProps) => {
  const {
    title
  } = block;
  const router = useRouter();
  return <Box id="form" pt={20} pb={20} sx={{
    backgroundColor: "#3EA4DC"
  }} data-sentry-element="Box" data-sentry-component="SpontaneousApplicationContactForm" data-sentry-source-file="SpontaneousApplicationContactForm.tsx">
      <Container maxWidth="md" data-sentry-element="Container" data-sentry-source-file="SpontaneousApplicationContactForm.tsx">
        <Grid container direction={"row"} spacing={10} justifyContent={"center"} data-sentry-element="Grid" data-sentry-source-file="SpontaneousApplicationContactForm.tsx">
          <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="SpontaneousApplicationContactForm.tsx">
            <Typography sx={{
            color: "white",
            align: "left",
            textAlign: "left",
            fontSize: {
              lg: "36px",
              xs: "24px"
            },
            fontWeight: {
              lg: 700,
              xs: 600
            },
            lineHeight: "48px"
          }} data-sentry-element="Typography" data-sentry-source-file="SpontaneousApplicationContactForm.tsx">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="SpontaneousApplicationContactForm.tsx">
            <WithMaterialUI form={{
            ...block,
            formName: router.asPath.replace("/", "")
          }} data-sentry-element="WithMaterialUI" data-sentry-source-file="SpontaneousApplicationContactForm.tsx" />
          </Grid>
        </Grid>
      </Container>
    </Box>;
};
export default SpontaneousApplicationContactForm;
const digitsOnly = (value: string) => /^[+]?\d+$/.test(value);
const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Enter a valid email").required("Email is required"),
  number: Yup.string().required("Please enter valid number").test("Digits only", "Digits only", digitsOnly).min(7, "Please enter valid number").max(15, "Maximum number limits"),
  location: Yup.string().required("Location is required"),
  job: Yup.string().required("Field is required"),
  motivation: Yup.string().required("Motivation is required"),
  contact: Yup.bool().oneOf([true], "Select is required").required("Select is required"),
  privacy: Yup.bool().oneOf([true], "Select is required").required("Select is required")
}).required();
const textFieldStyle = {
  border: 0,
  borderRadius: 0,
  "& fieldset": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  input: {
    backgroundColor: "white",
    "&::placeholder": {
      color: "#093950"
    }
  },
  "& .MuiSelect-select ": {
    backgroundColor: "white",
    "&::placeholder": {
      color: "#093950"
    }
  }
};
interface WithMaterialUIProps {
  form: APITypes.Components.ContentBlocks.SpontaneousApplicationForm;
}
const WithMaterialUI = ({
  form
}: WithMaterialUIProps) => {
  const {
    namePlaceholder,
    emailAddressPlaceholder,
    selectField,
    submitButton,
    checkBox,
    motivationPlaceholder,
    phoneNumberPlaceholder,
    formName
  } = form;
  const [isFormSent, setFormSent] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      number: "",
      location: "",
      job: "",
      motivation: "",
      contact: false,
      privacy: false
    },
    validationSchema: validationSchema,
    onSubmit: async (values, {
      resetForm
    }) => {
      try {
        const res = await submitForm(formName, values);
        if (res.status === 200) {
          resetForm();
          setFormSent(true);
          (window as any).dataLayer.push({
            event: `${formName} sent`
          });
        } else {
          console.error(res);
        }
      } catch (error) {
        console.error(error);
      }
    }
  });
  return <div data-sentry-component="WithMaterialUI" data-sentry-source-file="SpontaneousApplicationContactForm.tsx">
      {isFormSent ? <Box mt={-2}>
          <Typography sx={{
        color: "white",
        align: "left",
        textAlign: "left",
        fontSize: {
          lg: "36px",
          xs: "24px"
        },
        fontWeight: {
          lg: 700,
          xs: 600
        },
        lineHeight: "48px"
      }}>
            {submitButton.successMessage}
          </Typography>
          <Typography sx={{
        color: "white",
        align: "left",
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 400
      }}>
            {submitButton.successDescription}
          </Typography>
        </Box> : <Formik initialValues={{
      name: "",
      email: "",
      school: "",
      course: "",
      track: ""
    }} onSubmit={() => {}}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction={"row"} spacing={4}>
              <Grid container item xs={12} md={6} spacing={1}>
                <Grid item xs={12}>
                  <TextField fullWidth placeholder={namePlaceholder} sx={textFieldStyle} id="name" name="name" value={formik.values.name} onChange={formik.handleChange} error={formik.touched.name && Boolean(formik.errors.name)} helperText={formik.touched.name && formik.errors.name} />
                </Grid>
                <Grid item xs={12}>
                  <TextField sx={textFieldStyle} fullWidth id="email" name="email" placeholder={emailAddressPlaceholder} value={formik.values.email} onChange={formik.handleChange} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} />
                </Grid>
                <Grid item xs={12}>
                  <TextField sx={textFieldStyle} fullWidth id="number" name="number" placeholder={phoneNumberPlaceholder} value={formik.values.number} onChange={formik.handleChange} error={formik.touched.number && Boolean(formik.errors.number)} helperText={formik.touched.number && formik.errors.number} />
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} spacing={1}>
                {selectField.map((field, i) => <Grid item xs={12} key={i}>
                    <Select sx={{
                ...textFieldStyle,
                "& .MuiSelect-select .notranslate::after": field.selectPlaceholder ? {
                  content: `"${field.selectPlaceholder}"`,
                  opacity: 0.42
                } : {}
              }} fullWidth name={i === 0 ? "location" : "job"} id={i === 0 ? "location" : "job"} value={formik.values[i === 0 ? "location" : "job"]} onChange={formik.handleChange} error={formik.touched[i === 0 ? "location" : "job"] && Boolean(formik.errors[i === 0 ? "location" : "job"])}>
                      {field.selectOptions.map((option, i) => <MenuItem key={i} value={option.title}>
                          {option.title}
                        </MenuItem>)}
                    </Select>
                    <FormHelperText sx={{
                color: "red",
                ml: "14px"
              }}>
                      {formik.touched[i === 0 ? "location" : "job"] && formik.errors[i === 0 ? "location" : "job"]}
                    </FormHelperText>
                  </Grid>)}

                <Grid item xs={12}>
                  <TextField sx={textFieldStyle} fullWidth id="motivation" name="motivation" placeholder={motivationPlaceholder} value={formik.values.motivation} onChange={formik.handleChange} error={formik.touched.motivation && Boolean(formik.errors.motivation)} helperText={formik.touched.motivation && formik.errors.motivation} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={6} md={10}>
                  {checkBox.map((checkbox, i) => {
                return <Grid item xs={12} key={i}>
                        <Field sx={{
                    "& a": {
                      color: "black",
                      textDecoration: "none"
                    },
                    color: "white",
                    "& .MuiFormControlLabel-label": {
                      fontSize: "16px"
                    }
                  }} type="checkbox" name={i === 0 ? "contact" : "privacy"} as={FormControlLabel} control={<Checkbox sx={{
                    border: 0,
                    color: "white"
                  }} checkedIcon={<StopIcon sx={{
                    fontSize: "24px",
                    border: 0.2,
                    borderRadius: 0.5,
                    color: submitButton.hexColor,
                    backgroundColor: "white",
                    borderColor: "white"
                  }} />} />} checked={formik.values[i === 0 ? "contact" : "privacy"]} label={checkbox.label ? <Markdown content={checkbox.label} /> : checkbox.Name} onChange={formik.handleChange} />
                        <FormHelperText sx={{
                    color: "red",
                    ml: "14px"
                  }}>
                          {formik.touched[i === 0 ? "contact" : "privacy"] && formik.errors[i === 0 ? "contact" : "privacy"]}
                        </FormHelperText>
                      </Grid>;
              })}
                </Grid>
                <Grid container direction={"row"} item xs={12} spacing={2}>
                  <Grid item xs={5} sm={4} md={2}>
                    <Button size="large" variant="contained" type="submit" sx={{
                  fontSize: "18px",
                  fontWeight: 700,
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: submitButton.hexColor
                  },
                  borderRadius: 0
                }}>
                      {submitButton.title}
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography sx={{
                  color: "white",
                  fontSize: "12px",
                  fontWeight: 400
                }}>
                      {submitButton.subTitle}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Formik>}
    </div>;
};